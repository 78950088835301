import React, { ReactNode, useState } from 'react';
import {
  AccordionBaseContent,
  AccordionContent,
  AccordionContentTitle,
  AccordionTitle,
  SectionOne,
  SectionTwo,
  SkelletonAccordion,
  TableLine,
} from './style';
import RenderSkeletonOrContent from '../../../../../../components/renderSkeletonOrContent';
import Accordion from '../../../../../../components/accordion';
import { Button } from '../../../../../../components/button';
import { Skeleton } from '../../../../../../components/skeleton';
import { OutletPermify } from '../../../../../../components/outletPermify';
import { Roles } from '../../../../../../router/roles.routes';

type AccordionCategoryProps = {
  loading: boolean;
  setOpenCreateCategory?: () => void;
  children: any;
  name: string;
  description?: string;
};

export const AccordionCategorySection = ({
  loading,
  setOpenCreateCategory,
  children,
  name,
  description = name,
}: AccordionCategoryProps) => {
  const [open, setOpen] = useState(false);

  const toggleCreateModal = (event: any) => {
    // se clicar no botão, modal aparece
    if (open) {
      event.stopPropagation();
    }
    setOpenCreateCategory!();
  };

  return (
    <RenderSkeletonOrContent
      isLoading={loading}
      content={
        <Accordion
          id="accordion-docs"
          summaryClasses="accordion__header"
          variantstyle="base"
          summary={
            <AccordionBaseContent>
              <SectionOne>
                <img
                  src="images/arrow-down.svg"
                  className={
                    open ? 'accordion__icon' : 'accordion__icon rotate'
                  }
                  alt=""
                />
                <AccordionTitle>{name}</AccordionTitle>
              </SectionOne>
              <SectionTwo>
                {setOpenCreateCategory && (
                  <OutletPermify role={[Roles.CriarCategoria]}>
                    <Button variant="outlined" onClick={toggleCreateModal}>
                      + Novo Item
                    </Button>
                  </OutletPermify>
                )}
              </SectionTwo>
            </AccordionBaseContent>
          }
          handleExpanded={() => {
            setOpen(current => !current);
          }}
          value={open}
        >
          <AccordionContent>
            <AccordionContentTitle>{description}</AccordionContentTitle>
            <TableLine />
            {children}
          </AccordionContent>
        </Accordion>
      }
      fallback={
        <SkelletonAccordion>
          <Skeleton width="100%" height="40px" variant="text" />
        </SkelletonAccordion>
      }
    />
  );
};
