import React from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { api } from '../../../../../services/api';
import { verifyError } from '../../../../../utils/verifyError';
import { EmpresaEscrituradora } from '../fields';

export const useEscriturador = () => {
  const queryClient = useQueryClient();

  const getEscriturador = async () => {
    const getToast = toast.loading('Carregando consultores de listagem');
    try {
      const response: AxiosResponse<EmpresaEscrituradora[]> =
        await api.main.get(`/v1/empresas-escrituradoras`);
      toast.update(getToast, {
        render: 'Escrituradores carregados com sucesso!',
        type: 'success',
        isLoading: false,
        autoClose: 1,
      });
      return response?.data;
    } catch (error) {
      const message = verifyError(error, 'Erro ao carregar escrituradores');
      toast.update(getToast, {
        render: message,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  const {
    isLoading,
    isSuccess,
    isError,
    isIdle,
    data: escrituradores,
  } = useQuery(['escriturador'], getEscriturador, {});

  const invalidateQuery = async () => {
    await queryClient.invalidateQueries('escriturador');
  };

  const createDados = useMutation(async (payload: any) => {
    toast.loading('Criando empresa escrituradora');

    const response = await api.main.post(
      `/v1/empresas-escrituradoras`,
      payload,
    );

    invalidateQuery();
    return response;
  });

  const createEscriturador = (payload: any) => {
    createDados.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Escriturador registrado com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao registrar um novo escriturador',
        );
        toast.error(message);
      },
    });
  };

  const updateDados = useMutation(async (payload: any) => {
    toast.loading('Atualizando escriturador');

    const { id, company } = payload;

    const response = await api.main.put(
      `/v1/empresas-escrituradoras/${id}`,
      company,
    );

    invalidateQuery();

    return response;
  });

  const updateEscriturador = (payload: any) => {
    updateDados.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Escriturador atualizado com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(err, 'Erro ao atualizar o escriturador');
        toast.error(message);
      },
    });
  };

  const deleteDados = useMutation(async (payload: any) => {
    toast.loading('Excluindo o escriturador');

    const response = await api.main.delete(
      `/v1/empresas-escrituradoras/${payload}`,
    );

    invalidateQuery();

    return response;
  });

  const deleteEscriturador = (payload: any) => {
    deleteDados.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Escriturador excluído(a) com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(err, 'Erro ao deletar o escriturador');
        toast.error(message);
      },
    });
  };

  const createCollaborator = useMutation(async (payload: any) => {
    toast.loading('Criando colaborador para uma empresa escrituradora');
    const { id, consulter } = payload;

    const response = await api.main.post(
      `/v1/empresas-escrituradoras/${id}/consultores`,
      consulter,
    );

    invalidateQuery();
    return response;
  });

  const createEscrituradorCollaborator = (payload: any) => {
    createCollaborator.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Colaborador registrado com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao registrar um novo colaborador para uma empresa escrituradora',
        );
        toast.error(message);
      },
    });
  };

  const deleteCollaborator = useMutation(async (payload: any) => {
    toast.loading('Excluindo colaborador de empresa escrituradora');

    const { companyId, consulterId } = payload;

    const response = await api.main.delete(
      `/v1/empresas-escrituradoras/${companyId}/consultores/${consulterId}`,
    );

    invalidateQuery();
    return response;
  });

  const deleteEscrituradorCollaborator = (payload: any) => {
    deleteCollaborator.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Colaborador excluído(a) com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao excluir o colaborador de empresa escrituradora',
        );
        toast.error(message);
      },
    });
  };

  return {
    isLoading,
    isError,
    isIdle,
    isSuccess,
    escrituradores,
    getEscriturador,
    createEscriturador,
    updateEscriturador,
    deleteEscriturador,
    createEscrituradorCollaborator,
    deleteEscrituradorCollaborator,
    queryClient,
  };
};

export default useEscriturador;
