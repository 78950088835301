import React, { useState } from 'react';
import { Nomads } from './components/companies';

import { Content } from './styles';
import { ReportDate } from './components/reportDates';
import { CategoriesData } from './components/categories';
import { CategoriesArchiveData } from './components/categories/archive';

export const AdministracaoGlobal = () => {
  const [archiveItems, setArchiveItems] = useState(false);

  return (
    <Content>
      {archiveItems ? (
        <CategoriesArchiveData
          leaveArchiveItems={() => {
            setArchiveItems(false);
          }}
        />
      ) : (
        <>
          <CategoriesData
            checkArchiveItems={() => {
              setArchiveItems(true);
            }}
          />

          <ReportDate />

          <Nomads />
        </>
      )}
    </Content>
  );
};
