import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFieldArray, useForm } from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';
import { Controller } from '../../../../../../components/controller';
import { Modal } from '../../../../../../components/modal';
import { Buttons, ModalDescription, ModalTitle } from '../../styles';
import { Button } from '../../../../../../components/button';
import { Grid } from '../../../../../../components/grid';
import useCompanies from '../../../../../../hooks/companies';
import {
  dateToTimestamp,
  timestampToDate,
} from '../../../../../../utils/dateHandling';
import { EmpresasDisponiveisNomad, nomadSchema } from '../../fields';

interface FormData {
  nome: string;
  emailDoResponsavel: string;
  razaoSocial: string;
  cnpj: string;
  telefone: string;
  cpfDoResponsavel: string;
  empresas: EmpresasDisponiveisNomad[];
}

interface IButtonProps {
  description: boolean;
  closeDropdown: (e?: any) => void;
  updateCompany: (v: any) => void;
  createCompany: (v: any) => void;
  chosenCompany?: any;
}

export const CreateEditNomadModal: React.FC<IButtonProps> = ({
  description,
  closeDropdown,
  chosenCompany,
  updateCompany,
  createCompany,
}) => {
  const { companies, isLoading } = useCompanies({
    type: 'nomad',
    id: chosenCompany?.id,
  });

  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    chosenCompany?.empresas?.map((obj: any) => obj.id) ?? [],
  );

  const { handleSubmit, control, reset, getValues } = useForm<any>({
    resolver: yupResolver(nomadSchema),
  });

  const submitForm = (company: FormData) => {
    const newComp = {
      ...company,
      empresas: company?.empresas?.map((emp: any) => {
        return {
          contratacaoDaPrestacaoDoServico: dateToTimestamp(
            emp?.contratacaoDaPrestacaoDoServico,
          ),
          empresaId: emp?.empresaId,
        };
      }),
    };

    if (chosenCompany) {
      updateCompany({ id: chosenCompany?.id, company: newComp });
    } else {
      createCompany(newComp);
    }
    closeDropdown();
  };

  const handleCompanyOptions = (companyId?: string) => {
    const companyOptions = companies ?? [];
    const companySelected = companyId
      ? companyOptions?.filter(
          (comp: any) =>
            !selectedOptions.includes(comp.id) || comp.id === companyId,
        ) ?? []
      : companyOptions?.filter(
          (comp: any) => !selectedOptions.includes(comp.id),
        ) ?? [];

    return (
      companySelected?.map((company: any) => {
        return {
          value: company.id,
          label: company.nome,
        };
      }) ?? []
    );
  };

  const handleSelectValue = (e: string, index: number) => {
    // Verifica se o índice já existe no array
    if (selectedOptions[index]) {
      // Atualiza o índice existente
      setSelectedOptions(current => {
        const updatedOptions = [...current];
        updatedOptions[index] = e;
        return updatedOptions;
      });
    } else {
      // Adiciona um novo índice ao array
      setSelectedOptions(current => [...current, e]);
    }
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'empresas',
  });

  useEffect(() => {
    reset({
      nome: chosenCompany?.nome ?? '',
      emailDoResponsavel: chosenCompany?.emailDoResponsavel ?? '',
      razaoSocial: chosenCompany?.razaoSocial ?? '',
      cnpj: chosenCompany?.cnpj || '',
      cpfDoResponsavel: chosenCompany?.cpfDoResponsavel ?? '',
      empresas:
        chosenCompany?.empresas?.map((col: any) => {
          return {
            ...col,
            empresaId: col.id,
            contratacaoDaPrestacaoDoServico: timestampToDate(
              col?.contratacaoDaPrestacaoDoServico,
            ),
          };
        }) || [],
      telefone: chosenCompany?.telefone || '',
    });
  }, [reset]);

  return (
    <Modal open={description} handleClose={closeDropdown} width={1200}>
      <form onSubmit={handleSubmit(submitForm)}>
        <ModalTitle>
          {chosenCompany ? 'Editar' : 'Convidar'} consultor(a) de listagem
        </ModalTitle>
        <Grid container spacing={4}>
          {!chosenCompany && (
            <Grid item desktop={12}>
              <ModalDescription>
                Ao convidar um(a) Consultor(a) de Listagem Responsável, este(a)
                usuário(a) poderá convidar seus colaboradores, inserir, editar e
                excluir documentos e dados em nome de seus Emissores, validar as
                etapas de Emissão, gerar documentação para IPO e Listagem e
                submeter o pedido para análise da BEE4.
              </ModalDescription>
              <br />
              <ModalDescription>
                Caso este(a) Consultor(a) de Listagem não deva mais acessar o
                sistema, é de sua responsabilidade deletar o(a) usuário(a).
              </ModalDescription>
            </Grid>
          )}
          <Grid item desktop={6}>
            <Controller
              name="nome"
              control={control}
              type="input"
              label="Nome completo"
            />
          </Grid>
          <Grid item desktop={6}>
            <Controller
              name="emailDoResponsavel"
              control={control}
              type="input"
              label="E-mail do responsável"
              disabled={!!chosenCompany}
            />
          </Grid>
          <Grid item desktop={6}>
            <Controller
              label="Razão social"
              name="razaoSocial"
              control={control}
              type="input"
            />
          </Grid>
          <Grid item desktop={6}>
            <Controller
              name="cnpj"
              control={control}
              type="input"
              mask="cnpj"
              label="CNPJ"
              disabled={!!chosenCompany}
            />
          </Grid>
          <Grid item desktop={6}>
            <Controller
              name="cpfDoResponsavel"
              control={control}
              type="input"
              mask="cpf"
              label="CPF do responsável"
              disabled={!!chosenCompany}
            />
          </Grid>
          <Grid item desktop={6}>
            <Controller
              name="telefone"
              control={control}
              type="input"
              mask="phone"
              label="Telefone"
            />
          </Grid>

          {fields.map((item, index) => (
            <React.Fragment key={item.id}>
              <Grid item desktop={6}>
                <Controller
                  name={`empresas.${index}.empresaId`}
                  control={control}
                  type="select"
                  label="Empresa"
                  options={handleCompanyOptions(
                    getValues(`empresas.${index}.empresaId`),
                  )}
                  disabled={isLoading}
                  onSelectValue={e => {
                    handleSelectValue(e, index);
                  }}
                />
              </Grid>
              <Grid item desktop={5}>
                <Controller
                  name={`empresas.${index}.contratacaoDaPrestacaoDoServico`}
                  control={control}
                  type="input"
                  label="Data de contratação da prestação de serviço"
                  mask="date"
                />
              </Grid>

              {fields.length > 0 && (
                <Grid item desktop={1}>
                  <Button
                    type="button"
                    onClick={() => {
                      if (fields.length > 0) {
                        remove(index);
                      }
                    }}
                    variant="text"
                    disabled={!(fields.length > 0)}
                  >
                    <DeleteIcon htmlColor="#212E51" />
                  </Button>
                </Grid>
              )}
            </React.Fragment>
          ))}

          <Grid item desktop={6}>
            <Button
              onClick={() => {
                append({
                  empresaId: '',
                  contratacaoDaPrestacaoDoServico: '',
                });
              }}
              width="12rem"
              height="2.2rem"
              type="button"
            >
              + ADICIONAR EMPRESA
            </Button>
          </Grid>
        </Grid>
        <Buttons>
          <Button
            width="103px"
            height="40px"
            variant="outlined"
            onClick={closeDropdown}
          >
            CANCELAR
          </Button>
          <Button type="submit" width="103px" height="40px">
            SALVAR
          </Button>
        </Buttons>
      </form>
    </Modal>
  );
};
