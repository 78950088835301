import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Kebab } from '../../../../components/kebab';
import { Container } from '../../../../components/container';
import { Button } from '../../../../components/button';
import {
  ContentColumns,
  FirstColumn,
  FirstSection,
  FourthColumn,
  IndexColumns,
  Plus,
  SecondColumn,
  SecondSection,
  TableLine,
  TableRow,
  ThirdColumn,
  EmptyContent,
  Spacing,
  GenerateLinkDescription,
  LinkContent,
  LinkContainer,
  CopyLinkContainer,
} from './styles';
import { DeleteCompanyModal } from './components/deleteCompanyModal';
import { CreateEditEscrituradorModal } from './components/createEditCompanyModal';
import { DeleteCollaboratorModal } from './components/deleteCollaboratorModal';
import { AccordionCompany } from './components/accordion';
import { ShouldRenderChildren } from '../../../../components/shouldRenderChildren';
import RenderSkeletonOrContent from '../../../../components/renderSkeletonOrContent';
import { Skeleton } from '../../../../components/skeleton';
import useConsultorListagem from './hooks/consultorListagem';
import useEscriturador from './hooks/escriturador';
import useAuditor from './hooks/auditoria';
import { CreateEditCollaboratorModal } from './components/collaboratorModal';
import { CreateEditCollaboratorEscrituradorModal } from './components/collaboratorModal/escriturador';
import { CreateEditAuditoria } from './components/createEditCompanyModal/auditoria';
import useCompanies from '../../../../hooks/companies';
import { Section } from '../../styles';
import { Collaborator, EmpresaEscolhida, Empresas } from './fields';
import { CreateEditNomadModal } from './components/createEditCompanyModal/nomad';
import useCompanyLink from './hooks/companyLink';
import { OutletPermify } from '../../../../components/outletPermify';
import { Roles } from '../../../../router/roles.routes';

export const Nomads: React.FC<any> = () => {
  const { companies, isLoading } = useCompanies({});

  const {
    nomads,
    createConsultorDeListagem,
    updateConsultorDeListagem,
    deleteConsultorDeListagem,
    activateConsultorDeListagem,
    deleteConsultorDeListagemCollaborator,
    inviteConsultorDeListagem,
  } = useConsultorListagem();
  const {
    escrituradores,
    createEscriturador,
    updateEscriturador,
    deleteEscriturador,
    createEscrituradorCollaborator,
    deleteEscrituradorCollaborator,
  } = useEscriturador();

  const {
    auditores,
    createAuditor,
    updateAuditor,
    deleteAuditor,
    createAuditorCollaborator,
    updateAuditoriaCollaborator,
    deleteAuditorCollaborator,
  } = useAuditor();

  const [generateLink, setGenerateLink] = useState(false);

  const { linkInvite, invalidateQuery } = useCompanyLink({ generateLink });

  const [chosenCompany, setChosenCompany] = useState<
    EmpresaEscolhida | undefined
  >(undefined);
  const [chosenConsulter, setChosenConsulter] = useState<
    Collaborator | undefined
  >(undefined);
  const [addEditCollaboratorModal, setAddEditCollaboratorModal] =
    useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteColModal, setDeleteColModal] = useState(false);
  const [companyAddEditModal, setCompanyAddEditModal] = useState(false);
  const [companyType, setCompanyType] = useState<
    'nomad' | 'escriturador' | 'auditor' | undefined
  >('nomad');
  const [companiesId, setCompaniesId] = useState<string[] | undefined>([]);

  const [defaultCompanyValues, setDefaultCompanyValues] = useState<any>([]);

  const handleOnOpenModal = (
    e: string,
    company: EmpresaEscolhida,
    companyType?: 'nomad' | 'escriturador' | 'auditor',
  ) => {
    setCompanyType(companyType);
    handleCompaniesId(company?.empresas);
    setChosenCompany(company);

    if (e === 'delete') {
      setDeleteModal(true);
    } else if (e == 'edit') {
      setChosenCompany(company);
      setCompanyAddEditModal(true);
    } else {
      setAddEditCollaboratorModal(true);
    }
  };

  function handleCompaniesId(companyList?: Empresas[]) {
    const newCompanyList = companyList?.map(company => company.id);

    if (!newCompanyList) {
      setCompaniesId([]);
    }
    setCompaniesId(newCompanyList);
  }

  const handleCloseModal = () => {
    setChosenCompany(undefined);
    setChosenConsulter(undefined);
    setCompaniesId([]);
  };

  function collaboratorContentList(
    companyListed: EmpresaEscolhida,
    setDeleteColModal?: any,
    companyType?: 'nomad' | 'escriturador' | 'auditor',
  ) {
    const options =
      companyType === 'auditor'
        ? [
            {
              icon: <EditIcon htmlColor="#212E51" />,
              onClick: () => {
                setAddEditCollaboratorModal(true);
                setCompanyType(companyType);
              },
              name: 'Editar',
              roles: [Roles.EditarAuditor],
            },
            {
              icon: <DeleteIcon htmlColor="#212E51" />,
              onClick: () => {
                setDeleteColModal(true);
                setCompanyType(companyType);
              },
              name: 'Deletar',
              roles: [Roles.ExcluirAuditor],
            },
          ]
        : [
            {
              icon: <DeleteIcon htmlColor="#212E51" />,
              onClick: () => {
                setDeleteColModal(true);
                setCompanyType(companyType);
              },
              name: 'Deletar',
              roles: [
                companyType === 'nomad'
                  ? Roles.ExcluirCLResp
                  : Roles.ExcluirEscriturador,
              ],
            },
          ];

    return companyListed?.consultores?.map((consultor, index) => {
      const empresas = consultor?.empresasConsultor
        ?.map(
          item => companies?.filter((emp: any) => emp.id === item.empresaId)[0],
        )
        .map(emp => {
          return emp?.nome;
        })
        .join(', ');

      const companyTooBig = `${
        (empresas ? empresas.length : 0) > 38 ? '...' : ''
      }`;

      const empresasId = consultor?.empresasConsultor?.map(emp => {
        const result =
          auditores && auditores.length
            ? auditores[0]?.empresas?.find(
                (item: any) => item.id === emp.empresaId,
              )
            : [];
        return result?.id;
      });

      return (
        <React.Fragment key={consultor.id}>
          {companyListed.consultores &&
          index < companyListed.consultores.length ? (
            <TableLine />
          ) : null}
          <TableRow>
            <FirstColumn>
              <span>{consultor.nome}</span>
            </FirstColumn>
            <SecondColumn>
              <span>{consultor.email}</span>
            </SecondColumn>
            <ThirdColumn>
              <span>{`${
                empresas ? empresas.substring(0, 38) : ''
              }${companyTooBig}`}</span>
            </ThirdColumn>
            <FourthColumn>
              <Kebab
                options={options}
                onChange={e => {
                  setChosenConsulter(consultor);
                  setChosenCompany(companyListed);
                  setDefaultCompanyValues(empresasId);
                }}
                onClose={() => {
                  setChosenConsulter(undefined);
                  setChosenCompany(undefined);
                  setDefaultCompanyValues(undefined);
                }}
              />
            </FourthColumn>
          </TableRow>
        </React.Fragment>
      );
    });
  }

  function collaboratorList(
    setDeleteColModal: any,
    companyList?: EmpresaEscolhida[],
    companyType?: 'nomad' | 'escriturador' | 'auditor',
    reactivated?: boolean,
  ) {
    return companyList?.map(collaborator => {
      return (
        <SecondSection key={collaborator.id}>
          <AccordionCompany
            title={
              companyType === 'escriturador'
                ? collaborator.nomeDaEmpresa
                : companyType === 'auditor'
                ? collaborator.razaoSocial
                : collaborator.nome
            }
            onOpenModal={(e: any) => {
              handleOnOpenModal(e, collaborator, companyType);
            }}
            nomad={companyType === 'nomad'}
            status={collaborator.statusDoConvite}
            inviteConsultorDeListagem={() => {
              inviteConsultorDeListagem({ id: collaborator.id });
            }}
            ativo={(collaborator as any).ativo}
            reactivated={reactivated}
          >
            {collaborator.consultores && collaborator.consultores.length ? (
              <>
                <IndexColumns>
                  <FirstColumn>
                    <span>Nome do colabolador</span>
                  </FirstColumn>
                  <SecondColumn>
                    <span>E-mail</span>
                  </SecondColumn>
                  <ThirdColumn>
                    <span>Empresa(s)</span>
                  </ThirdColumn>
                  <FourthColumn />
                </IndexColumns>
                <ContentColumns>
                  {collaboratorContentList(
                    collaborator,
                    setDeleteColModal,
                    companyType,
                  )}
                </ContentColumns>{' '}
              </>
            ) : (
              <EmptyContent>
                {companyType === 'escriturador'
                  ? 'Essa empresa de escrituração ainda não possui colaborador, clique no botão de adicionar o colaborador para começar'
                  : companyType === 'auditor'
                  ? 'Esse auditor ainda não possui colaborador, clique no botão de adicionar o colaborador para começar'
                  : 'Esse consultor de listagem ainda não possui colaborador, clique no botão de adicionar o colaborador para começar'}
              </EmptyContent>
            )}
          </AccordionCompany>
        </SecondSection>
      );
    });
  }

  return (
    <Section>
      <Container title="Consultores de Listagem" titleMarginBottom="36px">
        <RenderSkeletonOrContent
          isLoading={isLoading}
          content={
            <>
              <OutletPermify role={[Roles.ConvidarConsultorListagem]}>
                <Button
                  height="44px"
                  fontSize="12px"
                  style={{
                    position: 'absolute',
                    top: '29px',
                    right: '44px',
                  }}
                  onClick={() => {
                    setCompanyAddEditModal(true);
                    setCompanyType('nomad');
                  }}
                >
                  <Plus>+</Plus>
                  <span>Consultor de listagem</span>
                </Button>
              </OutletPermify>
              <OutletPermify role={[Roles.VisualizarCLResps]}>
                {
                  collaboratorList(
                    setDeleteColModal,
                    nomads,
                    'nomad',
                    true,
                  ) as any
                }
              </OutletPermify>
            </>
          }
          fallback={
            <>
              <FirstSection>
                <Skeleton width="100%" height="40px" variant="rectangular" />
              </FirstSection>
              <Skeleton width="100%" height="200px" variant="rectangular" />
            </>
          }
        />
      </Container>
      <Spacing />
      <OutletPermify role={[Roles.GerarUrlEmissor]}>
        <Container title="Convite do emissor" titleMarginBottom="36px">
          <RenderSkeletonOrContent
            isLoading={isLoading}
            content={
              <>
                <Button
                  height="44px"
                  fontSize="12px"
                  style={{
                    position: 'absolute',
                    top: '29px',
                    right: '44px',
                  }}
                  onClick={() => {
                    if (generateLink) {
                      invalidateQuery();
                    } else {
                      setGenerateLink(true);
                    }
                  }}
                >
                  <span>GERAR LINK</span>
                </Button>
                <GenerateLinkDescription>
                  Gere um link único e envie para o emissor se cadastrar no
                  sistema de emissores.
                </GenerateLinkDescription>
                {linkInvite?.url && (
                  <LinkContainer>
                    <LinkContent>
                      <a
                        href={linkInvite?.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {linkInvite?.url}
                      </a>
                    </LinkContent>
                    <CopyToClipboard text={linkInvite?.url}>
                      <Button variant="text">
                        <CopyLinkContainer>
                          <img src="images/link.svg" alt="" />
                          COPIAR LINK
                        </CopyLinkContainer>
                      </Button>
                    </CopyToClipboard>
                  </LinkContainer>
                )}
              </>
            }
            fallback={
              <>
                <FirstSection>
                  <Skeleton width="100%" height="40px" variant="rectangular" />
                </FirstSection>
                <Skeleton width="100%" height="200px" variant="rectangular" />
              </>
            }
          />
        </Container>
      </OutletPermify>
      <Spacing />
      <Container title="Auditor" titleMarginBottom="36px">
        <RenderSkeletonOrContent
          isLoading={isLoading}
          content={
            <>
              <OutletPermify role={[Roles.CriarAuditor]}>
                <Button
                  height="44px"
                  fontSize="12px"
                  style={{
                    position: 'absolute',
                    top: '29px',
                    right: '44px',
                  }}
                  onClick={() => {
                    setCompanyAddEditModal(true);
                    setCompanyType('auditor');
                  }}
                >
                  <Plus>+</Plus>
                  <span>Auditor</span>
                </Button>
              </OutletPermify>
              <OutletPermify role={[Roles.VisualizarAuditor]}>
                {
                  collaboratorList(
                    setDeleteColModal,
                    auditores,
                    'auditor',
                  ) as any
                }
              </OutletPermify>
            </>
          }
          fallback={
            <>
              <FirstSection>
                <Skeleton width="100%" height="40px" variant="rectangular" />
              </FirstSection>
              <Skeleton width="100%" height="200px" variant="rectangular" />
            </>
          }
        />
      </Container>
      <Spacing />
      <Container title="Escriturador" titleMarginBottom="36px">
        <RenderSkeletonOrContent
          isLoading={isLoading}
          content={
            <>
              <OutletPermify role={[Roles.CriarEscriturador]}>
                <Button
                  height="44px"
                  fontSize="12px"
                  style={{
                    position: 'absolute',
                    top: '29px',
                    right: '44px',
                  }}
                  onClick={() => {
                    setCompanyAddEditModal(true);
                    setCompanyType('escriturador');
                  }}
                >
                  <Plus>+</Plus>
                  <span>Empresa de escrituração</span>
                </Button>
              </OutletPermify>
              <OutletPermify role={[Roles.VisualizarEscriturador]}>
                {
                  collaboratorList(
                    setDeleteColModal,
                    escrituradores,
                    'escriturador',
                  ) as any
                }
              </OutletPermify>
            </>
          }
          fallback={
            <>
              <FirstSection>
                <Skeleton width="100%" height="40px" variant="rectangular" />
              </FirstSection>
              <Skeleton width="100%" height="200px" variant="rectangular" />
            </>
          }
        />
      </Container>
      <ShouldRenderChildren shouldRender={deleteModal}>
        <DeleteCompanyModal
          description={deleteModal}
          closeDropdown={() => {
            setDeleteModal(false);
            handleCloseModal();
          }}
          companyId={chosenCompany?.id}
          companyType={companyType}
          deleteCompany={
            companyType === 'escriturador'
              ? deleteEscriturador
              : companyType === 'auditor'
              ? deleteAuditor
              : chosenCompany?.ativo
              ? deleteConsultorDeListagem
              : activateConsultorDeListagem
          }
          status={chosenCompany?.statusDoConvite ?? 0}
          ativo={chosenCompany?.ativo}
        />
      </ShouldRenderChildren>
      <ShouldRenderChildren
        shouldRender={companyAddEditModal && companyType === 'auditor'}
      >
        <CreateEditAuditoria
          description={companyAddEditModal && companyType === 'auditor'}
          defaultValue={companiesId}
          closeDropdown={() => {
            setCompanyAddEditModal(false);
            handleCloseModal();
          }}
          createCompany={createAuditor}
          chosenCompany={chosenCompany}
          updateCompany={updateAuditor}
        />
      </ShouldRenderChildren>

      <ShouldRenderChildren
        shouldRender={companyAddEditModal && companyType === 'nomad'}
      >
        <CreateEditNomadModal
          description={companyAddEditModal && companyType === 'nomad'}
          closeDropdown={() => {
            setCompanyAddEditModal(false);
            setCompaniesId([]);
            setChosenCompany(undefined);
          }}
          updateCompany={updateConsultorDeListagem}
          createCompany={createConsultorDeListagem}
          chosenCompany={chosenCompany}
        />
      </ShouldRenderChildren>

      <ShouldRenderChildren
        shouldRender={companyAddEditModal && companyType === 'escriturador'}
      >
        <CreateEditEscrituradorModal
          description={companyAddEditModal && companyType === 'escriturador'}
          closeDropdown={() => {
            setCompanyAddEditModal(false);
            setCompaniesId([]);
            setChosenCompany(undefined);
          }}
          updateCompany={updateEscriturador}
          createCompany={createEscriturador}
          chosenCompany={chosenCompany}
        />
      </ShouldRenderChildren>
      <ShouldRenderChildren
        shouldRender={addEditCollaboratorModal && companyType === 'auditor'}
      >
        <CreateEditCollaboratorModal
          description={addEditCollaboratorModal && companyType === 'auditor'}
          closeDropdown={() => {
            setAddEditCollaboratorModal(false);
            handleCloseModal();
          }}
          companyId={chosenCompany?.id ?? ''}
          companyType={companyType}
          collaborator={chosenConsulter}
          createCollaborator={createAuditorCollaborator}
          updateColaborador={updateAuditoriaCollaborator}
          defaultValue={defaultCompanyValues}
        />
      </ShouldRenderChildren>
      <ShouldRenderChildren
        shouldRender={
          addEditCollaboratorModal && companyType === 'escriturador'
        }
      >
        <CreateEditCollaboratorEscrituradorModal
          description={
            addEditCollaboratorModal && companyType === 'escriturador'
          }
          closeDropdown={() => {
            setAddEditCollaboratorModal(false);
            handleCloseModal();
          }}
          companyId={chosenCompany?.id ?? ''}
          handleCollaborator={createEscrituradorCollaborator}
        />
      </ShouldRenderChildren>
      <ShouldRenderChildren shouldRender={deleteColModal}>
        <DeleteCollaboratorModal
          description={deleteColModal}
          closeDropdown={() => {
            setDeleteColModal(false);
            handleCloseModal();
          }}
          status={chosenConsulter?.statusDoConvite ?? 0}
          companyId={chosenCompany?.id}
          consulterId={chosenConsulter?.id}
          deleteConsulter={
            companyType === 'escriturador'
              ? deleteEscrituradorCollaborator
              : companyType === 'auditor'
              ? deleteAuditorCollaborator
              : deleteConsultorDeListagemCollaborator
          }
        />
      </ShouldRenderChildren>
    </Section>
  );
};
