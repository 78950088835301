import React, { useEffect, useRef, useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import { dateToTimestamp, timestampToDate } from '../../utils/dateHandling';
import { Button } from '../../components/button';
import { Section } from '../../components/section';
import { Controller } from '../../components/controller';
import { useAuth } from '../../hooks/auth';
import { isValid } from '../../services/isValid';

import RenderSkeletonOrContent from '../../components/renderSkeletonOrContent';
import { Skeleton } from '../../components/skeleton';
import { Grid } from '../../components/grid';
import { Container } from '../../components/container';

import {
  Buttons,
  FormContent,
  InnerTitle,
  Margin,
  DeleteButton,
  InnerTitle2,
  InnerTitle3,
  RadioTitle,
  RadioDescription,
  RadioLabel2,
  DividedGrid,
} from './styles';
import { useEnterprise } from '../../hooks/enterprise';
import {
  listCompanyType,
  listCountries,
  listModeloVenda,
  listStates,
  listYesNo,
} from '../../utils/fields';

import {
  DadosGeraisForm,
  listAdressType,
  listControllerMajority,
  listControllerType,
  listSocialNetwork,
  listYesNoProgram,
  schema,
} from './fields';
import { DocumentList } from '../../components/documents';
import { obterEnderecoAsync } from '../../services/cep';
import { toBoolean } from '../../services/toBoolean';
import useDadosGerais from './hooks/useDadosGerais';
import { Tooltip } from '../../components/tooltip';
import { OutletPermify } from '../../components/outletPermify';
import { Roles } from '../../router/roles.routes';

export const DadosGerais = () => {
  const primeiroAcesso = useRef(true);
  const { currentEnterpriseId } = useEnterprise();
  const { dadosGerais, updateDadosGerais, isLoading } = useDadosGerais({
    currentEnterpriseId,
  });

  const { handleSubmit, control, reset, watch, setValue, getValues } =
    useForm<DadosGeraisForm>({
      resolver: yupResolver(schema),
    });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'acionistas',
  });

  const responsavelJuridico = watch('assessoriaJuridica.responsavelJuridico');

  const naoResponsavel = watch(
    'executivoResponsavelPeloRegistro.responsavelPontoDeContatoDaEmpresa',
  );

  const tipoDeCompanhiaControladora = watch('tipoDeCompanhiaControladora');

  // const outrosInput = watch('tipoDeEmpresaControladoraMajoritaria');

  const country = watch('paisSede');

  // const temControlador = watch('temControlador');

  // const entreOsControladoresExisteAlgumEstatal = watch(
  //   'entreOsControladoresExisteAlgumEstatal',
  // );

  useEffect(() => {
    if (tipoDeCompanhiaControladora !== 2) {
      setValue('tipoDeEmpresaControladoraMajoritaria', '');
    }
  }, [tipoDeCompanhiaControladora]);

  useEffect(() => {
    if (isDisabled || primeiroAcesso.current) {
      primeiroAcesso.current = false;
      return;
    }

    if (responsavelJuridico === 1) {
      setValue('assessoriaJuridica.razaoSocial', '');
      setValue('assessoriaJuridica.cnpj', '');
      setValue('assessoriaJuridica.inicioDeContratoDePrestacaoDeServico', '');
      setValue('assessoriaJuridica.tipoDeEndereco', null as any);
      setValue('assessoriaJuridica.cep', '');
      setValue('assessoriaJuridica.rua', '');
      setValue('assessoriaJuridica.numero', '');
      setValue('assessoriaJuridica.complemento', '');
      setValue('assessoriaJuridica.bairro', '');
      setValue('assessoriaJuridica.municipio', '');
      setValue('assessoriaJuridica.estado', '');
      setValue('assessoriaJuridica.telefone', '');
      setValue('assessoriaJuridica.cpfDoResponsavel', '');
    }
  }, [responsavelJuridico]);

  useEffect(() => {
    if (fields.length) fields.forEach((item, index) => remove(index));
    if (dadosGerais?.acionistas.length)
      dadosGerais?.acionistas.forEach((item: any) => append(item));
  }, [dadosGerais?.acionistas]);

  const handleEndereco1 = async (cep: string) => {
    if (!cep || cep?.length !== 8) {
      return null;
    }
    if (cep.length === 8) {
      const { endereco, bairro, cidade, uf } = await obterEnderecoAsync(cep);

      setValue('rua', endereco);
      setValue('bairro', bairro);
      setValue('municipio', cidade);
      setValue('estado', uf);
    }
    return null;
  };

  const handleEndereco2 = async (cep: string) => {
    if (!cep || cep?.length !== 8) {
      return null;
    }
    if (cep.length === 8) {
      const { endereco, bairro, cidade, uf } = await obterEnderecoAsync(cep);

      setValue('executivoResponsavelPeloRegistro.rua', endereco);
      setValue('executivoResponsavelPeloRegistro.bairro', bairro);
      setValue('executivoResponsavelPeloRegistro.municipio', cidade);
      setValue('executivoResponsavelPeloRegistro.estado', uf);
    }
    return null;
  };

  const handleEndereco3 = async (cep: string) => {
    if (!cep || cep?.length !== 8) {
      return null;
    }
    if (cep.length === 8) {
      const { endereco, bairro, cidade, uf } = await obterEnderecoAsync(cep);

      setValue('assessoriaJuridica.rua', endereco);
      setValue('assessoriaJuridica.bairro', bairro);
      setValue('assessoriaJuridica.municipio', cidade);
      setValue('assessoriaJuridica.estado', uf);
    }
    return null;
  };

  useEffect(() => {
    if (!dadosGerais) {
      return;
    }

    reset({
      nomeFantasia: dadosGerais?.nomeFantasia,
      razaoSocial: dadosGerais?.razaoSocial,
      cnpj: dadosGerais?.cnpj,
      tipo: dadosGerais?.tipo,
      orgaoSocietario: dadosGerais?.orgaoSocietario,
      numeroDeFuncionarios: dadosGerais?.numeroDeFuncionarios,
      setorDeAtuacao: dadosGerais?.setorDeAtuacao,
      emailContatoDaEmpresa: dadosGerais?.emailContatoDaEmpresa,
      modeloDeVenda: dadosGerais?.modeloDeVenda,
      logo: dadosGerais?.logo,
      breveHistorico: dadosGerais?.breveHistorico,
      comoFuncionaOModeloDeNegocio: dadosGerais?.comoFuncionaOModeloDeNegocio,
      descricaoDasAtividades: dadosGerais?.descricaoDasAtividades,
      diferenciaisDoEmissor: dadosGerais?.diferenciaisDoEmissor,
      linhasDeReceita: dadosGerais?.linhasDeReceita,
      principaisFornecedores: dadosGerais?.principaisFornecedores,
      dataDaConstituicao: timestampToDate(dadosGerais?.dataDaConstituicao),
      dataDeEncerramentoDoExercicioSocial: timestampToDate(
        dadosGerais?.dataDeEncerramentoDoExercicioSocial,
      ),
      dataDoPedidoDeRegistroNaBEE4: timestampToDate(
        dadosGerais?.dataDoPedidoDeRegistroNaBEE4,
      ),
      website: dadosGerais?.website,
      urlDoFacebook: dadosGerais?.urlDoFacebook,
      urlDoYouTube: dadosGerais?.urlDoYouTube,
      urlDoLinkedIn: dadosGerais?.urlDoLinkedIn,
      urlDoInstagram: dadosGerais?.urlDoInstagram,
      urlDoTwitter: dadosGerais?.urlDoTwitter,
      principalRedeSocial: dadosGerais?.principalRedeSocial,
      paisSede: dadosGerais?.paisSede,
      cep: dadosGerais?.cep,
      rua: dadosGerais?.rua,
      numero: dadosGerais?.numero,
      complemento: dadosGerais?.complemento,
      bairro: dadosGerais?.bairro,
      municipio: dadosGerais?.municipio,
      estado: dadosGerais?.estado,
      tipoDeEndereco: dadosGerais?.tipoDeEndereco,
      telefone: dadosGerais?.telefone,
      executivoResponsavelPeloRegistro: {
        ...dadosGerais?.executivoResponsavelPeloRegistro,
        responsavelPontoDeContatoDaEmpresa:
          dadosGerais?.executivoResponsavelPeloRegistro.responsavelPontoDeContatoDaEmpresa?.toString(),
        dateDeInicioNaEmpresa: timestampToDate(
          dadosGerais?.executivoResponsavelPeloRegistro.dateDeInicioNaEmpresa,
        ),
      },
      nomeDoCEO: dadosGerais?.nomeDoCEO,
      cpfDoCEO: dadosGerais?.cpfDoCEO,
      desdeQuandoOCEOOcupaOCargo: timestampToDate(
        dadosGerais?.desdeQuandoOCEOOcupaOCargo,
      ),
      linkedInDoCEO: dadosGerais?.linkedInDoCEO,
      miniCVDoCEO: dadosGerais?.miniCVDoCEO,
      nomeDoPresidenteDoConselho: dadosGerais?.nomeDoPresidenteDoConselho,
      cpfDoPresidenteDoConselho: dadosGerais?.cpfDoPresidenteDoConselho,
      desdeQuandoOPresidenteDoConselhoOcupaOCargo: timestampToDate(
        dadosGerais?.desdeQuandoOPresidenteDoConselhoOcupaOCargo,
      ),
      linkedInDoPresidenteDoConselho:
        dadosGerais?.linkedInDoPresidenteDoConselho,
      miniCVDoPresidenteDoConselho: dadosGerais?.miniCVDoPresidenteDoConselho,
      temControlador: dadosGerais?.temControlador?.toString(),
      entreOsControladoresExisteAlgumEstatal:
        dadosGerais?.entreOsControladoresExisteAlgumEstatal?.toString(),
      porcentagemDosControladores: dadosGerais?.porcentagemDosControladores,
      nomeColaboradorEstatal: dadosGerais?.nomeColaboradorEstatal,
      membrosDoConselho: dadosGerais?.membrosDoConselho,
      numeroDeMembrosIndependentesDoConselho:
        dadosGerais?.numeroDeMembrosIndependentesDoConselho,
      periodicidadeDasReunioesDoConselho:
        dadosGerais?.periodicidadeDasReunioesDoConselho,
      responsabilidadeEObrigacoesDoConselho:
        dadosGerais?.responsabilidadeEObrigacoesDoConselho,
      umOuMaisMembrosTemPercentualNaCompanhia:
        dadosGerais?.umOuMaisMembrosTemPercentualNaCompanhia?.toString(),
      nomeDaCompanhiaControladora: dadosGerais?.nomeDaCompanhiaControladora,
      tipoDeCompanhiaControladora: dadosGerais?.tipoDeCompanhiaControladora,
      percentualDaControladora: dadosGerais?.percentualDaControladora,
      tipoDeEmpresaControladoraMajoritaria:
        dadosGerais?.tipoDeEmpresaControladoraMajoritaria,
      tipoDeEmpresaControladoraMajoritariaDetalhes:
        dadosGerais?.tipoDeEmpresaControladoraMajoritariaDetalhes,
      acionistas: dadosGerais?.acionistas,
      ultimaAlteracaoDoAcordoDeAcionistas: timestampToDate(
        dadosGerais?.ultimaAlteracaoDoAcordoDeAcionistas,
      ),
      osPrincipaisExecutivosSaoSocios:
        dadosGerais?.osPrincipaisExecutivosSaoSocios?.toString(),
      possuiProgramaDePartnershipOuStockOptions:
        dadosGerais?.possuiProgramaDePartnershipOuStockOptions?.toString(),
      dadosBancariosDaEmpresa: dadosGerais?.dadosBancariosDaEmpresa,
      consultorDeListagem: {
        ...dadosGerais?.consultorDeListagem,
        inicioDoContratoDePrestacaoDeServico: timestampToDate(
          dadosGerais?.consultorDeListagem
            ?.inicioDoContratoDePrestacaoDeServico,
        ),
      },
      escriturador: {
        ...dadosGerais?.escriturador,
        inicioDoContratoDePrestacaoDeServico: timestampToDate(
          dadosGerais?.escriturador?.inicioDoContratoDePrestacaoDeServico,
        ),
      },
      auditoria: {
        ...dadosGerais?.auditoria,
        inicioDoContratoDePrestacaoDeServico: timestampToDate(
          dadosGerais?.auditoria?.inicioDoContratoDePrestacaoDeServico,
        ),
        registroNaCvm: timestampToDate(dadosGerais?.auditoria.registroNaCvm),
      },
      assessoriaJuridica: dadosGerais?.assessoriaJuridica,
      assessoriaJuridicaDadosContatoFinanceiro:
        dadosGerais?.assessoriaJuridicaDadosContatoFinanceiro,
      assessoriaJuridicaDiretorDeDivulgacoesDeInformacoesAoMercado:
        dadosGerais?.assessoriaJuridicaDiretorDeDivulgacoesDeInformacoesAoMercado,
    });

    if (dadosGerais?.isReadOnly === true) setIsDisabled(true);
  }, [dadosGerais, reset]);

  const [isDisabled, setIsDisabled] = useState(false);

  const submitForm = (data: DadosGeraisForm) => {
    if (!isValid.cnpj(data?.cnpj === undefined ? '' : data.cnpj)) {
      toast.error('Insira um CNPJ válido');
    } else {
      // delete data.auditoria;
      // delete data.escriturador;

      updateDadosGerais({
        ...data,
        logo: data?.logo ?? {},
        temControlador: toBoolean(data?.temControlador),
        entreOsControladoresExisteAlgumEstatal: toBoolean(
          data?.entreOsControladoresExisteAlgumEstatal,
        ),
        dataDaConstituicao: dateToTimestamp(data?.dataDaConstituicao),
        dataDeEncerramentoDoExercicioSocial: dateToTimestamp(
          data?.dataDeEncerramentoDoExercicioSocial,
        ),
        dataDoPedidoDeRegistroNaBEE4: dateToTimestamp(
          data?.dataDoPedidoDeRegistroNaBEE4,
        ),
        executivoResponsavelPeloRegistro: {
          ...data?.executivoResponsavelPeloRegistro,
          responsavelPontoDeContatoDaEmpresa: toBoolean(
            data?.executivoResponsavelPeloRegistro
              ?.responsavelPontoDeContatoDaEmpresa,
          ),
          dateDeInicioNaEmpresa: dateToTimestamp(
            data?.executivoResponsavelPeloRegistro?.dateDeInicioNaEmpresa,
          ),
        },
        desdeQuandoOCEOOcupaOCargo: dateToTimestamp(
          data?.desdeQuandoOCEOOcupaOCargo,
        ),
        desdeQuandoOPresidenteDoConselhoOcupaOCargo: dateToTimestamp(
          data?.desdeQuandoOPresidenteDoConselhoOcupaOCargo,
        ),
        ultimaAlteracaoDoAcordoDeAcionistas: dateToTimestamp(
          data?.ultimaAlteracaoDoAcordoDeAcionistas,
        ),
        escriturador: {
          ...data?.escriturador,
          inicioDoContratoDePrestacaoDeServico: dateToTimestamp(
            data?.escriturador?.inicioDoContratoDePrestacaoDeServico,
          ),
        },
      });
    }
  };

  const assessorType = [
    {
      label: (
        <div>
          <RadioTitle>Interno</RadioTitle>
          <RadioDescription>Pertence a empresa</RadioDescription>
        </div>
      ),
      value: 1,
    },
    {
      label: (
        <div>
          <RadioTitle>Externo</RadioTitle>
          <RadioDescription>Não pertence a empresa</RadioDescription>
        </div>
      ),
      value: 2,
    },
  ];

  const handleJuridico = () => {
    if ([1, 2].includes(responsavelJuridico))
      return (
        <>
          {responsavelJuridico === 2 && (
            <>
              <Grid item desktop={6}>
                <Controller
                  name="assessoriaJuridica.razaoSocial"
                  control={control}
                  type="input"
                  label="Razão social"
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item desktop={3}>
                <Controller
                  name="assessoriaJuridica.cnpj"
                  control={control}
                  type="input"
                  label="CNPJ"
                  mask="cnpj"
                  disabled={isDisabled}
                />
              </Grid>
              {/* <Grid item desktop={3}>
                <Controller
                  name="assessoriaJuridica.inicioDeContratoDePrestacaoDeServico"
                  control={control}
                  type="input"
                  label="Início da prestação de serviço"
                  disabled={isDisabled}
                />
              </Grid> */}
              <Grid item desktop={3}>
                <Controller
                  name="assessoriaJuridica.tipoDeEndereco"
                  control={control}
                  type="select"
                  label="Tipo de endereço"
                  options={listAdressType}
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item desktop={3}>
                <Controller
                  name="assessoriaJuridica.cep"
                  control={control}
                  type="input"
                  mask="cep"
                  label="CEP"
                  onBlur={() =>
                    handleEndereco3(getValues('assessoriaJuridica.cep'))
                  }
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item desktop={3}>
                <Controller
                  name="assessoriaJuridica.rua"
                  control={control}
                  type="input"
                  label="Endereço"
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item desktop={3}>
                <DividedGrid>
                  <Controller
                    name="assessoriaJuridica.numero"
                    control={control}
                    type="input"
                    label="Número"
                    disabled={isDisabled}
                  />
                  <Controller
                    name="assessoriaJuridica.complemento"
                    control={control}
                    type="input"
                    label="Complemento"
                    disabled={isDisabled}
                  />
                </DividedGrid>
              </Grid>
              <Grid item desktop={3}>
                <Controller
                  name="assessoriaJuridica.bairro"
                  control={control}
                  type="input"
                  label="Bairro"
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item desktop={3}>
                <Controller
                  name="assessoriaJuridica.municipio"
                  control={control}
                  type="input"
                  label="Município"
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item desktop={3}>
                <Controller
                  name="assessoriaJuridica.estado"
                  control={control}
                  type="input"
                  label="Estado"
                  disabled={isDisabled}
                />
              </Grid>
              {/* <Grid item desktop={3}>
                <Controller
                  name="assessoriaJuridica.telefone"
                  control={control}
                  type="input"
                  label="Telefone da empresa"
                  disabled={isDisabled}
                  mask="phone"
                />
              </Grid>{' '} */}
            </>
          )}
          <Grid item desktop={6}>
            <Controller
              name="assessoriaJuridica.nomeDoResponsavel"
              control={control}
              type="input"
              label="Nome do responsável"
              disabled={isDisabled}
            />
          </Grid>
          {/* <Grid item desktop={3}>
            <Controller
              name="assessoriaJuridica.cpfDoResponsavel"
              control={control}
              type="input"
              label="CPF do responsável"
              disabled={isDisabled}
              mask="cpf"
            />
          </Grid> */}
          <Grid item desktop={3}>
            <Controller
              name="assessoriaJuridica.telefoneDoResponsavel"
              control={control}
              type="input"
              label="Telefone do responsável"
              disabled={isDisabled}
              mask="phone"
            />
          </Grid>
          <Grid item desktop={3}>
            <Controller
              name="assessoriaJuridica.emailDoResponsavel"
              control={control}
              type="input"
              label="E-mail do responsável"
              disabled={isDisabled}
            />
          </Grid>
        </>
      );
    return <></>;
  };

  // const handleShareholders = () => (
  //   <>
  //     {fields.length ? (
  //       fields.map((item, index) => (
  //         // eslint-disable-next-line react/no-array-index-key
  //         <React.Fragment key={index}>
  //           <Grid item desktop={6}>
  //             <Controller
  //               name={`acionistas.${index}.nome`}
  //               label="Nome do acionista controlador"
  //               control={control}
  //               type="input"
  //               disabled={isDisabled}
  //             />
  //           </Grid>

  //           <Grid item desktop={2}>
  //             <Controller
  //               name={`acionistas.${index}.participacao`}
  //               control={control}
  //               type="input"
  //               label="Part. (%)"
  //               mask="percentage"
  //               disabled={isDisabled}
  //             />
  //           </Grid>

  //           {fields.length > 1 ? (
  //             <>
  //               <Grid item desktop={1}>
  //                 <DeleteButton
  //                   type="button"
  //                   disabled={isDisabled}
  //                   onClick={() => {
  //                     if (fields.length > 1) {
  //                       remove(index);
  //                     }
  //                   }}
  //                 >
  //                   <DeleteIcon htmlColor="#212E51" />
  //                 </DeleteButton>
  //               </Grid>
  //               <Grid item desktop={3}>
  //                 {fields.length - 1 === index && (
  //                   <Button
  //                     onClick={() => {
  //                       append({ nome: '', participacao: null });
  //                     }}
  //                     variant="text"
  //                     disabled={isDisabled}
  //                     style={{ height: '56px' }}
  //                   >
  //                     + ADICIONAR ACIONISTA CONTROLADOR
  //                   </Button>
  //                 )}
  //               </Grid>
  //             </>
  //           ) : (
  //             <Grid item desktop={4}>
  //               <Button
  //                 onClick={() => {
  //                   append({ nome: '', participacao: null });
  //                 }}
  //                 variant="text"
  //                 disabled={isDisabled}
  //                 style={{ height: '56px' }}
  //               >
  //                 + ADICIONAR ACIONISTA CONTROLADOR
  //               </Button>
  //             </Grid>
  //           )}
  //         </React.Fragment>
  //       ))
  //     ) : (
  //       <>
  //         <Grid item desktop={9} />
  //         <Grid item desktop={3}>
  //           <Button
  //             onClick={() => {
  //               append({ nome: '', participacao: null });
  //             }}
  //             variant="text"
  //             disabled={isDisabled}
  //             style={{ height: '56px' }}
  //           >
  //             + ADICIONAR ACIONISTA CONTROLADOR
  //           </Button>
  //         </Grid>
  //       </>
  //     )}
  //   </>
  // );

  return (
    <FormContent onSubmit={handleSubmit(submitForm)}>
      <Section>
        <RenderSkeletonOrContent
          isLoading={isLoading}
          content={
            <Container
              title="Dados do negócio"
              button={
                !isDisabled && (
                  <OutletPermify role={[Roles.EditarCamposDadoGeral]}>
                    <Button
                      width="170px"
                      height="40px"
                      type="submit"
                      disabled={isDisabled}
                    >
                      SALVAR DADOS
                    </Button>
                  </OutletPermify>
                )
              }
            >
              <Grid container spacing={4}>
                <Grid item desktop={6}>
                  <Controller
                    name="razaoSocial"
                    control={control}
                    type="input"
                    label="Razão social"
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={6}>
                  <Controller
                    name="nomeFantasia"
                    control={control}
                    type="input"
                    label="Nome fantasia"
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="cnpj"
                    control={control}
                    type="input"
                    mask="cnpj"
                    label="CNPJ"
                    placeholder="Emitido no site da Receita Federal"
                    disabled
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="tipo"
                    control={control}
                    type="radio"
                    label="Tipo"
                    options={listCompanyType}
                    row
                    disabled
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="dataDeEncerramentoDoExercicioSocial"
                    control={control}
                    type="input"
                    label="Data de encerramento do exercício"
                    mask="date"
                    placeholder="dd/mm/aaaa"
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="dataDoPedidoDeRegistroNaBEE4"
                    control={control}
                    type="input"
                    label="Data do pedido do registro na BEE4"
                    mask="date"
                    placeholder="dd/mm/aaaa"
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="dataDaConstituicao"
                    control={control}
                    type="input"
                    label="Data da constituição"
                    mask="date"
                    placeholder="dd/mm/aaaa"
                    disabled={isDisabled}
                  />
                </Grid>

                <Grid item desktop={3}>
                  <Controller
                    name="orgaoSocietario"
                    control={control}
                    type="input"
                    label="Órgão societário"
                    disabled={isDisabled}
                  />
                </Grid>

                <Grid item desktop={3}>
                  <Controller
                    name="website"
                    control={control}
                    type="input"
                    label="Site"
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="emailContatoDaEmpresa"
                    control={control}
                    type="input"
                    label="E-mail da empresa"
                    disabled={isDisabled}
                  />
                </Grid>

                <Grid item desktop={3}>
                  <Controller
                    name="tipoDeEndereco"
                    control={control}
                    type="select"
                    label="Tipo de endereço"
                    options={listAdressType}
                    disabled={isDisabled}
                  />
                </Grid>

                <Grid item desktop={3}>
                  <Controller
                    name="paisSede"
                    control={control}
                    type="autocomplete"
                    label="País de sede"
                    autocompleteOptions={listCountries}
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="cep"
                    control={control}
                    type="input"
                    label="CEP"
                    mask="cep"
                    onBlur={() => handleEndereco1(getValues('cep'))}
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="rua"
                    control={control}
                    type="input"
                    label="Endereço"
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Grid container spacing={4}>
                    <Grid item desktop={6}>
                      <Controller
                        name="numero"
                        control={control}
                        type="input"
                        label="Número"
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item desktop={6}>
                      <Controller
                        name="complemento"
                        control={control}
                        type="input"
                        label="Complemento"
                        disabled={isDisabled}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="bairro"
                    control={control}
                    type="input"
                    label="Bairro"
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="municipio"
                    control={control}
                    type="input"
                    label="Município"
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={3}>
                  {country === 'Brasil' ? (
                    <Controller
                      name="estado"
                      control={control}
                      type="select"
                      label="Estado"
                      disabled={isDisabled}
                      options={listStates}
                    />
                  ) : (
                    <Controller
                      name="estado"
                      control={control}
                      type="input"
                      label="Estado"
                      disabled={isDisabled}
                    />
                  )}
                </Grid>

                <Grid item desktop={3}>
                  <Controller
                    name="telefone"
                    control={control}
                    type="input"
                    label="Telefone da empresa"
                    mask="phone"
                    disabled={isDisabled}
                  />
                </Grid>
                {/* <Grid item desktop={3}>
                  <Controller
                    name="numeroDeFuncionarios"
                    control={control}
                    type="input"
                    mask="integer"
                    label="Número de funcionários"
                    format="####"
                    disabled={isDisabled}
                  />
                </Grid> */}
                {/* <Grid item desktop={6}>
                  <Controller
                    name="modeloDeVenda"
                    control={control}
                    type="select"
                    label="Modelo de venda"
                    options={listModeloVenda}
                    disabled={isDisabled}
                  />
                </Grid> */}
                <Grid item desktop={6} />
                <Grid item desktop={6}>
                  <Controller
                    name="logo"
                    control={control}
                    type="uploadButton"
                    disabled={isDisabled}
                    label="Upload logo da empresa"
                    description="JPG ou PNG (largura máxima de 220px)"
                  />
                </Grid>

                <Grid item desktop={6}>
                  <Controller
                    name="setorDeAtuacao"
                    control={control}
                    type="input"
                    label="Setor de atuação"
                    textarea
                    maxLength={7000}
                    height="135px"
                    placeholder="Máx. 7000 caracteres"
                    disabled={isDisabled}
                  />
                </Grid>

                <Grid item desktop={6}>
                  <Controller
                    name="breveHistorico"
                    control={control}
                    type="input"
                    label="Breve histórico"
                    textarea
                    maxLength={1000}
                    height="135px"
                    placeholder="Máx. 1000 caracteres"
                    disabled={isDisabled}
                  />
                </Grid>
                {/* <Grid item desktop={6}>
                  <Controller
                    name="comoFuncionaOModeloDeNegocio"
                    control={control}
                    type="input"
                    label="Explicação do modelo de negócio"
                    textarea
                    maxLength={1000}
                    placeholder="Máx. 1000 caracteres"
                    height="135px"
                    disabled={isDisabled}
                  />
                </Grid> */}
                <Grid item desktop={6}>
                  <Controller
                    name="descricaoDasAtividades"
                    control={control}
                    type="input"
                    label="Descrição das atividades"
                    textarea
                    maxLength={1000}
                    height="135px"
                    placeholder="Breve descrição das atividades da empresa emissores e eventuais controladas (Máx. 1000 caracteres)"
                    disabled={isDisabled}
                  />
                </Grid>
                {/* <Grid item desktop={6}>
                  <Controller
                    name="diferenciaisDoEmissor"
                    control={control}
                    type="input"
                    label="Diferenciais"
                    textarea
                    maxLength={1000}
                    height="135px"
                    placeholder="Breve descrição dos principais diferenciais da sua empresa (Máx. 1000 caracteres)"
                    disabled={isDisabled}
                  />
                </Grid> */}
              </Grid>
              <InnerTitle>Redes sociais (até 5)</InnerTitle>
              <Grid container spacing={4}>
                <Grid item desktop={6}>
                  <Controller
                    name="principalRedeSocial"
                    control={control}
                    type="select"
                    label="Principal rede social"
                    options={listSocialNetwork}
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={6}>
                  <Controller
                    name="urlDoFacebook"
                    control={control}
                    type="input"
                    label="Facebook"
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={6}>
                  <Controller
                    name="urlDoInstagram"
                    control={control}
                    type="input"
                    label="Instagram"
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={6}>
                  <Controller
                    name="urlDoYouTube"
                    control={control}
                    type="input"
                    label="Youtube"
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={6}>
                  <Controller
                    name="urlDoTwitter"
                    control={control}
                    type="input"
                    label="Twitter"
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={6}>
                  <Controller
                    name="urlDoLinkedIn"
                    control={control}
                    type="input"
                    label="LinkedIn"
                    disabled={isDisabled}
                  />
                </Grid>
              </Grid>
              {/* <Grid container spacing={4}>
                <Grid item desktop={4}>
                  <InnerTitle2>
                    Principais linhas de receita (até 5)
                  </InnerTitle2>
                </Grid>
                <Grid item desktop={4}>
                  <InnerTitle2>Relevância (% da receita)</InnerTitle2>
                </Grid>
                <Grid item desktop={4}>
                  <InnerTitle2>Principais fornecedores (até 5)</InnerTitle2>
                </Grid>
                <Grid item desktop={4}>
                  <Controller
                    name="linhasDeReceita.0.nome"
                    control={control}
                    type="input"
                    label=""
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={4}>
                  <Controller
                    name="linhasDeReceita.0.percentualDaReceita"
                    control={control}
                    type="input"
                    label=""
                    mask="percentage"
                    noZero
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={4}>
                  <Controller
                    name="principaisFornecedores.0.nome"
                    control={control}
                    type="input"
                    label=""
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={4}>
                  <Controller
                    name="linhasDeReceita.1.nome"
                    control={control}
                    type="input"
                    label=""
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={4}>
                  <Controller
                    name="linhasDeReceita.1.percentualDaReceita"
                    control={control}
                    type="input"
                    label=""
                    mask="percentage"
                    noZero
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={4}>
                  <Controller
                    name="principaisFornecedores.1.nome"
                    control={control}
                    type="input"
                    label=""
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={4}>
                  <Controller
                    name="linhasDeReceita.2.nome"
                    control={control}
                    type="input"
                    label=""
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={4}>
                  <Controller
                    name="linhasDeReceita.2.percentualDaReceita"
                    control={control}
                    type="input"
                    label=""
                    noZero
                    mask="percentage"
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={4}>
                  <Controller
                    name="principaisFornecedores.2.nome"
                    control={control}
                    type="input"
                    label=""
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={4}>
                  <Controller
                    name="linhasDeReceita.3.nome"
                    control={control}
                    type="input"
                    label=""
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={4}>
                  <Controller
                    name="linhasDeReceita.3.percentualDaReceita"
                    control={control}
                    type="input"
                    label=""
                    noZero
                    mask="percentage"
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={4}>
                  <Controller
                    name="principaisFornecedores.3.nome"
                    control={control}
                    type="input"
                    label=""
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={4}>
                  <Controller
                    name="linhasDeReceita.4.nome"
                    control={control}
                    type="input"
                    label=""
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={4}>
                  <Controller
                    name="linhasDeReceita.4.percentualDaReceita"
                    control={control}
                    type="input"
                    label=""
                    noZero
                    mask="percentage"
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={4}>
                  <Controller
                    name="principaisFornecedores.4.nome"
                    control={control}
                    type="input"
                    label=""
                    disabled={isDisabled}
                  />
                </Grid>
              </Grid> */}
            </Container>
          }
          fallback={
            <Container>
              <Skeleton width="100%" height="62px" variant="text" />
              <Skeleton width="100%" height="62px" variant="text" />
              <Skeleton width="100%" height="168px" variant="text" />
              <Skeleton width="100%" height="168px" variant="text" />
              <Skeleton width="100%" height="62px" variant="text" />
              <Skeleton width="100%" height="62px" variant="text" />
              <Skeleton width="100%" height="62px" variant="text" />
            </Container>
          }
        />
      </Section>
      <Section>
        <RenderSkeletonOrContent
          isLoading={isLoading}
          content={
            <Container
              title="Informações do management e governança"
              button={
                !isDisabled && (
                  <OutletPermify role={[Roles.EditarCamposDadoGeral]}>
                    <Button
                      width="170px"
                      height="40px"
                      type="submit"
                      disabled={isDisabled}
                    >
                      SALVAR DADOS
                    </Button>
                  </OutletPermify>
                )
              }
            >
              <Grid container spacing={4}>
                <Grid item desktop={6}>
                  <Grid container spacing={6}>
                    <Grid item desktop={6}>
                      <Controller
                        name="nomeDoCEO"
                        control={control}
                        type="input"
                        label="Nome completo do CEO"
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item desktop={6}>
                      <Controller
                        name="cpfDoCEO"
                        control={control}
                        type="input"
                        label="CPF do CEO"
                        mask="cpf"
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item desktop={6}>
                      <Controller
                        name="desdeQuandoOCEOOcupaOCargo"
                        control={control}
                        type="input"
                        label="Desde quando ocupa o cargo"
                        mask="date"
                        placeholder="dd/mm/aaaa"
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item desktop={6}>
                      <Controller
                        name="linkedInDoCEO"
                        control={control}
                        type="input"
                        label="Link do LinkedIn"
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item desktop={6}>
                      <Controller
                        name="nomeDoPresidenteDoConselho"
                        control={control}
                        type="input"
                        label="Nome completo do Pres. do conselho"
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item desktop={6}>
                      <Controller
                        name="cpfDoPresidenteDoConselho"
                        control={control}
                        type="input"
                        label="CPF do Presidente do conselho"
                        mask="cpf"
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item desktop={6}>
                      <Controller
                        name="desdeQuandoOPresidenteDoConselhoOcupaOCargo"
                        control={control}
                        type="input"
                        label="Desde quando ocupa o cargo"
                        mask="date"
                        placeholder="dd/mm/aaaa"
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item desktop={6}>
                      <Controller
                        name="linkedInDoPresidenteDoConselho"
                        control={control}
                        type="input"
                        label="Link do LinkedIn"
                        disabled={isDisabled}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item desktop={6}>
                  <Grid container spacing={4}>
                    <Grid item desktop={12}>
                      <Controller
                        name="miniCVDoCEO"
                        control={control}
                        type="input"
                        label="Mini CV do CEO"
                        textarea
                        maxLength={300}
                        height="135px"
                        placeholder="Máx. 300 caracteres"
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item desktop={12}>
                      <Controller
                        name="miniCVDoPresidenteDoConselho"
                        control={control}
                        type="input"
                        label="Mini CV do Presidente do conselho"
                        textarea
                        maxLength={300}
                        height="135px"
                        placeholder="Máx. 300 caracteres"
                        disabled={isDisabled}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="temControlador"
                    control={control}
                    type="radio"
                    row
                    label={
                      <div
                        style={{
                          display: 'flex',
                          gap: '0.8rem',
                          alignItems: 'center',
                        }}
                      >
                        Tem controlador(es)?
                        <Tooltip title="*Entende-se por acionista controlador a pessoa, natural ou jurídica, ou o grupo de pessoas vinculadas por acordo de voto, ou sob controle comum, que: a) é titular de direitos de sócio que lhe assegurem, de modo permanente, a maioria dos votos nas deliberações da assembleia-geral e o poder de eleger a maioria dos administradores da companhia; e b) usa efetivamente seu poder para dirigir as atividades sociais e orientar o funcionamento dos órgãos da companhia.">
                          <img
                            src="images/System/18.svg"
                            alt=""
                            style={{ height: '16px' }}
                          />
                        </Tooltip>
                      </div>
                    }
                    options={listYesNo}
                    disabled={isDisabled}
                  />
                </Grid>
                {/* {temControlador === 'true' ? (
                  <>
                    <Grid item desktop={3}>
                      <Controller
                        name="porcentagemDosControladores"
                        control={control}
                        type="input"
                        mask="percentage"
                        label="% do(s) controlador(es)"
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item desktop={3}>
                      <Controller
                        name="entreOsControladoresExisteAlgumEstatal"
                        control={control}
                        type="select"
                        label="Algum dos controladores é Estatal?"
                        options={listYesNo}
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item desktop={3}>
                      {entreOsControladoresExisteAlgumEstatal === 'true' && (
                        <Controller
                          name="nomeColaboradorEstatal"
                          control={control}
                          type="input"
                          label="Nome do controlador estatal"
                          disabled={isDisabled}
                        />
                      )}
                    </Grid>
                  </>
                ) : ( */}
                <Grid item desktop={9} />
                {/* )} */}
              </Grid>

              <InnerTitle>Membros do conselho</InnerTitle>
              <Grid container spacing={4}>
                <Grid item desktop={6}>
                  <Grid container spacing={3}>
                    <Grid item desktop={12}>
                      <InnerTitle3>Nome</InnerTitle3>
                    </Grid>
                    <Grid item desktop={12}>
                      <Controller
                        name="membrosDoConselho.0.nome"
                        control={control}
                        type="input"
                        label=""
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item desktop={12}>
                      <Controller
                        name="membrosDoConselho.1.nome"
                        control={control}
                        type="input"
                        label=""
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item desktop={12}>
                      <Controller
                        name="membrosDoConselho.2.nome"
                        control={control}
                        type="input"
                        label=""
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item desktop={12}>
                      <Controller
                        name="membrosDoConselho.3.nome"
                        control={control}
                        type="input"
                        label=""
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item desktop={12}>
                      <Controller
                        name="membrosDoConselho.4.nome"
                        control={control}
                        type="input"
                        label=""
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item desktop={12}>
                      <Controller
                        name="membrosDoConselho.5.nome"
                        control={control}
                        type="input"
                        label=""
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item desktop={12}>
                      <Controller
                        name="membrosDoConselho.6.nome"
                        control={control}
                        type="input"
                        label=""
                        disabled={isDisabled}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item desktop={3}>
                  <Grid container spacing={3}>
                    <Grid item desktop={12}>
                      <InnerTitle3>CPF</InnerTitle3>
                    </Grid>
                    <Grid item desktop={12}>
                      <Controller
                        name="membrosDoConselho.0.cpf"
                        control={control}
                        type="input"
                        mask="cpf"
                        label=""
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item desktop={12}>
                      <Controller
                        name="membrosDoConselho.1.cpf"
                        control={control}
                        type="input"
                        mask="cpf"
                        label=""
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item desktop={12}>
                      <Controller
                        name="membrosDoConselho.2.cpf"
                        control={control}
                        type="input"
                        mask="cpf"
                        label=""
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item desktop={12}>
                      <Controller
                        name="membrosDoConselho.3.cpf"
                        control={control}
                        type="input"
                        mask="cpf"
                        label=""
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item desktop={12}>
                      <Controller
                        name="membrosDoConselho.4.cpf"
                        control={control}
                        type="input"
                        mask="cpf"
                        label=""
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item desktop={12}>
                      <Controller
                        name="membrosDoConselho.5.cpf"
                        control={control}
                        type="input"
                        mask="cpf"
                        label=""
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item desktop={12}>
                      <Controller
                        name="membrosDoConselho.6.cpf"
                        control={control}
                        type="input"
                        mask="cpf"
                        label=""
                        disabled={isDisabled}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item desktop={3}>
                  <Grid container spacing={3}>
                    <Grid item desktop={12}>
                      <InnerTitle3>Qualificação</InnerTitle3>
                    </Grid>
                    <Grid item desktop={12}>
                      <Controller
                        name="membrosDoConselho.0.qualificacao"
                        control={control}
                        type="input"
                        label=""
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item desktop={12}>
                      <Controller
                        name="membrosDoConselho.1.qualificacao"
                        control={control}
                        type="input"
                        label=""
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item desktop={12}>
                      <Controller
                        name="membrosDoConselho.2.qualificacao"
                        control={control}
                        type="input"
                        label=""
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item desktop={12}>
                      <Controller
                        name="membrosDoConselho.3.qualificacao"
                        control={control}
                        type="input"
                        label=""
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item desktop={12}>
                      <Controller
                        name="membrosDoConselho.4.qualificacao"
                        control={control}
                        type="input"
                        label=""
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item desktop={12}>
                      <Controller
                        name="membrosDoConselho.5.qualificacao"
                        control={control}
                        type="input"
                        label=""
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item desktop={12}>
                      <Controller
                        name="membrosDoConselho.6.qualificacao"
                        control={control}
                        type="input"
                        label=""
                        disabled={isDisabled}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Margin />
              <Grid container spacing={4}>
                <Grid item desktop={6}>
                  <Grid container spacing={7}>
                    <Grid item desktop={6}>
                      <Controller
                        name="numeroDeMembrosIndependentesDoConselho"
                        control={control}
                        type="input"
                        mask="integer"
                        label="Número de membros independentes"
                        noZero={false}
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item desktop={6}>
                      <Controller
                        name="periodicidadeDasReunioesDoConselho"
                        control={control}
                        type="input"
                        label="Periodicidade das reuniões"
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item desktop={12}>
                      <Controller
                        name="umOuMaisMembrosTemPercentualNaCompanhia"
                        control={control}
                        type="select"
                        options={listYesNo}
                        label="Um ou mais membros tem % na Companhia?"
                        disabled={isDisabled}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item desktop={6}>
                  <Controller
                    name="responsabilidadeEObrigacoesDoConselho"
                    control={control}
                    type="input"
                    label="Responsabilidades e obrigações do conselho"
                    textarea
                    maxLength={700}
                    height="135px"
                    placeholder="Máx. 700 caracteres"
                    disabled={isDisabled}
                  />
                </Grid>
              </Grid>
            </Container>
          }
          fallback={
            <div>
              <Container>
                <Skeleton width="100%" height="62px" variant="text" />
                <Skeleton width="100%" height="62px" variant="text" />
                <Skeleton width="100%" height="62px" variant="text" />
                <Skeleton width="100%" height="62px" variant="text" />
              </Container>
            </div>
          }
        />
      </Section>
      {/* <Section>
        <RenderSkeletonOrContent
          isLoading={isLoading}
          content={
            <Container
              title="Societário"
              button={
                !isDisabled && (
                  <Button
                    width="170px"
                    height="40px"
                    type="submit"
                    disabled={isDisabled}
                  >
                    SALVAR DADOS
                  </Button>
                )
              }
            >
              <Grid container spacing={4}>
                <Grid item desktop={3}>
                  <Controller
                    name="tipoDeCompanhiaControladora"
                    control={control}
                    type="radio"
                    label="Companhia com controle"
                    options={listControllerType}
                    row
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="tipoDeEmpresaControladoraMajoritaria"
                    control={control}
                    type="select"
                    label="Tipo da empresa controladora majoritária"
                    options={listControllerMajority}
                    disabled={isDisabled || tipoDeCompanhiaControladora !== 2}
                  />
                </Grid>
                {outrosInput === 'Outros' ? (
                  <Grid item desktop={6}>
                    <Controller
                      name="tipoDeEmpresaControladoraMajoritariaDetalhes"
                      control={control}
                      type="input"
                      label="Outros"
                      disabled={isDisabled || tipoDeCompanhiaControladora !== 2}
                    />
                  </Grid>
                ) : (
                  <Grid item desktop={6} />
                )}
                <Grid item desktop={3}>
                  <Controller
                    name="ultimaAlteracaoDoAcordoDeAcionistas"
                    control={control}
                    type="input"
                    label="Última alteração do acordo de acionistas"
                    mask="date"
                    placeholder="dd/mm/aaaa"
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="possuiProgramaDePartnershipOuStockOptions"
                    control={control}
                    type="select"
                    label="Partnership ou Stock Option?"
                    options={listYesNoProgram}
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="osPrincipaisExecutivosSaoSocios"
                    control={control}
                    type="select"
                    label="Os principais executivos são sócios?"
                    options={listYesNo}
                    disabled={isDisabled}
                  />
                </Grid>

                <Grid item desktop={3}>
                  <Controller
                    name="percentualDaControladora"
                    control={control}
                    type="input"
                    label="Percentual do Controlador"
                    mask="percentage"
                    disabled={isDisabled}
                  />
                </Grid>

                <Grid item desktop={6}>
                  <Controller
                    name="nomeDaCompanhiaControladora"
                    control={control}
                    type="input"
                    label="Nome do Controlador"
                    disabled={isDisabled}
                  />
                </Grid>

                <Grid item desktop={6} />

                {handleShareholders()}
              </Grid>
            </Container>
          }
          fallback={
            <Container>
              <Skeleton width="100%" height="62px" variant="text" />
              <Skeleton width="100%" height="62px" variant="text" />
              <Skeleton width="100%" height="62px" variant="text" />
              <Skeleton width="100%" height="62px" variant="text" />
            </Container>
          }
        />
      </Section> */}
      <Section>
        <RenderSkeletonOrContent
          isLoading={isLoading}
          content={
            <Container
              title="Assessoria jurídica"
              button={
                !isDisabled && (
                  <OutletPermify role={[Roles.EditarCamposDadoGeral]}>
                    <Button
                      width="170px"
                      height="40px"
                      type="submit"
                      disabled={isDisabled}
                    >
                      SALVAR DADOS
                    </Button>
                  </OutletPermify>
                )
              }
            >
              <Grid container spacing={4}>
                <Grid item desktop={6}>
                  <Controller
                    name="assessoriaJuridica.responsavelJuridico"
                    control={control}
                    type="radio"
                    label={
                      <RadioLabel2>
                        O <strong>Responsável jurídico</strong> é:
                      </RadioLabel2>
                    }
                    options={assessorType}
                    row
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={6} />
                {handleJuridico()}
              </Grid>
            </Container>
          }
          fallback={
            <Container>
              <Skeleton width="100%" height="62px" variant="text" />
              <Skeleton width="100%" height="62px" variant="text" />
              <Skeleton width="100%" height="62px" variant="text" />
            </Container>
          }
        />
      </Section>
      {/* <Section>
        <RenderSkeletonOrContent
          isLoading={isLoading}
          content={
            <Container
              title="Responsável financeiro"
              button={
                !isDisabled && (
                  <Button
                    width="170px"
                    height="40px"
                    type="submit"
                    disabled={isDisabled}
                  >
                    SALVAR DADOS
                  </Button>
                )
              }
            >
              <Grid container spacing={4}>
                <Grid item desktop={6}>
                  <Controller
                    name="assessoriaJuridicaDadosContatoFinanceiro.nome"
                    control={control}
                    type="input"
                    label="Nome responsável"
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="assessoriaJuridicaDadosContatoFinanceiro.telefone"
                    control={control}
                    type="input"
                    label="Telefone do responsável"
                    mask="phone"
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="assessoriaJuridicaDadosContatoFinanceiro.email"
                    control={control}
                    type="input"
                    label="E-mail do responsável"
                    disabled={isDisabled}
                  />
                </Grid>
              </Grid>
            </Container>
          }
          fallback={
            <Container>
              <Skeleton width="100%" height="62px" variant="text" />
              <Skeleton width="100%" height="62px" variant="text" />
              <Skeleton width="100%" height="62px" variant="text" />
            </Container>
          }
        />
      </Section> */}
      {/* <Section>
        <RenderSkeletonOrContent
          isLoading={isLoading}
          content={
            <Container
              title="Diretor(a) de divulgações de Informações de Mercado"
              button={
                !isDisabled && (
                  <Button
                    width="170px"
                    height="40px"
                    type="submit"
                    disabled={isDisabled}
                  >
                    SALVAR DADOS
                  </Button>
                )
              }
            >
              <Grid container spacing={4}>
                <Grid item desktop={6}>
                  <Controller
                    name="assessoriaJuridicaDiretorDeDivulgacoesDeInformacoesAoMercado.nome"
                    control={control}
                    type="input"
                    label="Nome responsável"
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="assessoriaJuridicaDiretorDeDivulgacoesDeInformacoesAoMercado.telefone"
                    control={control}
                    type="input"
                    label="Telefone do responsável"
                    mask="phone"
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="assessoriaJuridicaDiretorDeDivulgacoesDeInformacoesAoMercado.email"
                    control={control}
                    type="input"
                    label="E-mail do responsável"
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={6}>
                  <Controller
                    name="assessoriaJuridicaDiretorDeDivulgacoesDeInformacoesAoMercado.posicaoNaEmpresa"
                    control={control}
                    type="input"
                    label="Posição na empresa"
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={6} />
              </Grid>
            </Container>
          }
          fallback={
            <Container>
              <Skeleton width="100%" height="62px" variant="text" />
              <Skeleton width="100%" height="62px" variant="text" />
              <Skeleton width="100%" height="62px" variant="text" />
            </Container>
          }
        />
      </Section> */}
      <Section>
        <RenderSkeletonOrContent
          isLoading={isLoading}
          content={
            <Container title="Consultor de listagem credenciado na BEE4">
              <Grid container spacing={4}>
                <Grid item desktop={6}>
                  <Controller
                    name="consultorDeListagem.razaoSocial"
                    control={control}
                    type="input"
                    label="Razão social"
                    disabled
                  />
                </Grid>
                <Grid item desktop={6}>
                  <Controller
                    name="consultorDeListagem.inicioDoContratoDePrestacaoDeServico"
                    control={control}
                    type="input"
                    label="Início do contrato de prestação de serviço"
                    mask="date"
                    placeholder="dd/mm/aaaa"
                    disabled
                  />
                </Grid>
                <Grid item desktop={6}>
                  <Controller
                    name="consultorDeListagem.nomeDoResponsavelTecnico"
                    control={control}
                    type="input"
                    label="Nome do responsável técnico"
                    disabled
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="consultorDeListagem.cpf"
                    control={control}
                    type="input"
                    label="CPF"
                    mask="cpf"
                    disabled
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="consultorDeListagem.cnpj"
                    control={control}
                    type="input"
                    label="CNPJ"
                    mask="cnpj"
                    disabled
                  />
                </Grid>
                <Grid item desktop={6}>
                  <Controller
                    name="consultorDeListagem.telefone"
                    control={control}
                    type="input"
                    label="Telefone"
                    mask="phone"
                    disabled
                  />
                </Grid>
                <Grid item desktop={6}>
                  <Controller
                    name="consultorDeListagem.email"
                    control={control}
                    type="input"
                    label="E-mail"
                    disabled
                  />
                </Grid>
              </Grid>
            </Container>
          }
          fallback={
            <Container>
              <Skeleton width="100%" height="62px" variant="text" />
              <Skeleton width="100%" height="62px" variant="text" />
              <Skeleton width="100%" height="62px" variant="text" />
            </Container>
          }
        />
      </Section>

      <Section>
        <RenderSkeletonOrContent
          isLoading={isLoading}
          content={
            <Container title="Escriturador registrado na CVM e credenciado na BEE4">
              <Grid container spacing={4}>
                <Grid item desktop={6}>
                  <Controller
                    name="escriturador.razaoSocial"
                    control={control}
                    type="input"
                    label="Razão social"
                    disabled
                  />
                </Grid>
                <Grid item desktop={6}>
                  <Controller
                    name="escriturador.nomeDaEmpresa"
                    control={control}
                    type="input"
                    label="Nome da Empresa"
                    disabled
                  />
                </Grid>
                <Grid item desktop={6}>
                  <Controller
                    name="escriturador.nomeDoResponsavelTecnico"
                    control={control}
                    type="input"
                    label="Nome do responsável técnico"
                    disabled
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="escriturador.cpf"
                    control={control}
                    type="input"
                    label="CPF"
                    mask="cpf"
                    disabled
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="escriturador.cnpj"
                    control={control}
                    type="input"
                    label="CNPJ"
                    mask="cnpj"
                    disabled
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="escriturador.telefone"
                    control={control}
                    type="input"
                    label="Telefone"
                    mask="phone"
                    disabled
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="escriturador.inicioDoContratoDePrestacaoDeServico"
                    control={control}
                    type="input"
                    label="Início do contrato de prestação de serviço"
                    mask="date"
                    placeholder="dd/mm/aaaa"
                    disabled
                  />
                </Grid>
                <Grid item desktop={6}>
                  <Controller
                    name="escriturador.email"
                    control={control}
                    type="input"
                    label="E-mail"
                    disabled
                  />
                </Grid>
              </Grid>
            </Container>
          }
          fallback={
            <Container>
              <Skeleton width="100%" height="62px" variant="text" />
              <Skeleton width="100%" height="62px" variant="text" />
              <Skeleton width="100%" height="62px" variant="text" />
            </Container>
          }
        />
      </Section>

      <Section>
        <RenderSkeletonOrContent
          isLoading={isLoading}
          content={
            <Container title="Auditor independente credenciado na CVM">
              <Grid container spacing={4}>
                <Grid item desktop={6}>
                  <Controller
                    name="auditoria.razaoSocial"
                    control={control}
                    type="input"
                    label="Razão social"
                    disabled
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="auditoria.cnpj"
                    control={control}
                    type="input"
                    label="CNPJ"
                    mask="cnpj"
                    disabled
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="auditoria.cep"
                    control={control}
                    type="input"
                    mask="cep"
                    label="CEP"
                    disabled
                  />
                </Grid>
                <Grid item desktop={6}>
                  <Controller
                    name="auditoria.rua"
                    control={control}
                    type="input"
                    label="Rua"
                    disabled
                  />
                </Grid>
                <Grid item desktop={6}>
                  <Controller
                    name="auditoria.bairro"
                    control={control}
                    type="input"
                    label="Bairro"
                    disabled
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="auditoria.cidade"
                    control={control}
                    type="input"
                    label="Município"
                    disabled
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="auditoria.uf"
                    control={control}
                    type="input"
                    label="Estado"
                    disabled
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="auditoria.tipoDeEndereco"
                    control={control}
                    type="select"
                    label="Tipo de Endereco"
                    options={listAdressType}
                    disabled
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="auditoria.registroNaCvm"
                    control={control}
                    type="input"
                    label="Data de registro na CVM"
                    mask="date"
                    disabled
                  />
                </Grid>

                <Grid item desktop={6}>
                  <Controller
                    name="auditoria.inicioDoContratoDePrestacaoDeServico"
                    control={control}
                    type="input"
                    label="Data de início do contrato de prestação de serviço"
                    mask="date"
                    disabled
                  />
                </Grid>
                <Grid item desktop={6}>
                  <Controller
                    name="auditoria.nomeDoResponsavelTecnico"
                    control={control}
                    type="input"
                    label="Nome do responsável"
                    disabled
                  />
                </Grid>
                <Grid item desktop={6}>
                  <Controller
                    name="auditoria.email"
                    control={control}
                    type="input"
                    label="E-mail do responsável"
                    disabled
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="auditoria.cpf"
                    control={control}
                    type="input"
                    label="CPF do responsável"
                    mask="cpf"
                    disabled
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="auditoria.telefone"
                    control={control}
                    type="input"
                    label="Telefone do responsável"
                    mask="phone"
                    disabled
                  />
                </Grid>
              </Grid>
            </Container>
          }
          fallback={
            <Container>
              <Skeleton width="100%" height="62px" variant="text" />
              <Skeleton width="100%" height="62px" variant="text" />
              <Skeleton width="100%" height="62px" variant="text" />
            </Container>
          }
        />
      </Section>

      <Section>
        <RenderSkeletonOrContent
          isLoading={isLoading}
          content={
            <Container
              title="Ponto de Contato na Empresa"
              button={
                !isDisabled && (
                  <OutletPermify role={[Roles.EditarCamposDadoGeral]}>
                    <Button
                      width="170px"
                      height="40px"
                      type="submit"
                      disabled={isDisabled}
                    >
                      SALVAR DADOS
                    </Button>
                  </OutletPermify>
                )
              }
            >
              <Grid container spacing={4}>
                <Grid item desktop={6}>
                  <Controller
                    name="executivoResponsavelPeloRegistro.nome"
                    control={control}
                    type="input"
                    label="Nome completo"
                    disabled={isDisabled}
                  />
                </Grid>
                {/* <Grid item desktop={6}>
                  <Controller
                    name="executivoResponsavelPeloRegistro.cpf"
                    control={control}
                    type="input"
                    label="CPF"
                    mask="cpf"
                    disabled={isDisabled}
                  />
                </Grid> */}
                {/* <Grid item desktop={3}>
                  <Controller
                    name="executivoResponsavelPeloRegistro.cep"
                    control={control}
                    type="input"
                    label="CEP"
                    mask="cep"
                    onBlur={() =>
                      handleEndereco2(
                        getValues('executivoResponsavelPeloRegistro.cep'),
                      )
                    }
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="executivoResponsavelPeloRegistro.rua"
                    control={control}
                    type="input"
                    label="Endereço"
                    disabled={isDisabled}
                  />
                </Grid> */}
                {/* <Grid item desktop={3}>
                  <Grid container spacing={4}>
                    <Grid item desktop={6}>
                      <Controller
                        name="executivoResponsavelPeloRegistro.numero"
                        control={control}
                        type="input"
                        label="Número"
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item desktop={6}>
                      <Controller
                        name="executivoResponsavelPeloRegistro.complemento"
                        control={control}
                        type="input"
                        label="Complemento"
                        disabled={isDisabled}
                      />
                    </Grid>
                  </Grid>
                </Grid> */}
                {/* <Grid item desktop={3}>
                  <Controller
                    name="executivoResponsavelPeloRegistro.bairro"
                    control={control}
                    type="input"
                    label="Bairro"
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="executivoResponsavelPeloRegistro.municipio"
                    control={control}
                    type="input"
                    label="Município"
                    disabled={isDisabled}
                  />
                </Grid> */}
                {/* <Grid item desktop={3}>
                  <Controller
                    name="executivoResponsavelPeloRegistro.estado"
                    control={control}
                    type="select"
                    label="Estado"
                    disabled={isDisabled}
                    options={listStates}
                  />
                </Grid> */}
                {/* <Grid item desktop={3}>
                  <Controller
                    name="executivoResponsavelPeloRegistro.tipoDeEndereco"
                    control={control}
                    type="select"
                    label="Tipo de endereço"
                    options={listAdressType}
                    disabled={isDisabled}
                  />
                </Grid> */}
                {/* <Grid item desktop={3}>
                  <Controller
                    name="executivoResponsavelPeloRegistro.dateDeInicioNaEmpresa"
                    control={control}
                    type="input"
                    label="Data de início na empresa emissora"
                    mask="date"
                    placeholder="dd/mm/aaaa"
                    disabled={isDisabled}
                  />
                </Grid> */}
                <Grid item desktop={6}>
                  <Controller
                    name="executivoResponsavelPeloRegistro.telefone"
                    control={control}
                    type="input"
                    label="Telefone"
                    mask="phone"
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={6}>
                  <Controller
                    name="executivoResponsavelPeloRegistro.email"
                    control={control}
                    type="input"
                    label="E-mail"
                    disabled={isDisabled}
                  />
                </Grid>
                {/* <Grid item desktop={6}>
                  <Controller
                    name="executivoResponsavelPeloRegistro.responsavelPontoDeContatoDaEmpresa"
                    control={control}
                    type="radio"
                    label="Este responsável deve ser o ponto de contato da empresa?"
                    options={listYesNo}
                    row
                    disabled={isDisabled}
                  />
                </Grid> */}
                {naoResponsavel === 'false' ? (
                  <>
                    <Grid item desktop={6}>
                      <Controller
                        name="executivoResponsavelPeloRegistro.nomeDoResponsavel"
                        control={control}
                        type="input"
                        label="Nome do responsável"
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item desktop={6}>
                      <Controller
                        name="executivoResponsavelPeloRegistro.emailDoResponsavel"
                        control={control}
                        type="input"
                        label="E-mail do responsável"
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item desktop={6}>
                      <Controller
                        name="executivoResponsavelPeloRegistro.telefoneDoResponsavel"
                        control={control}
                        type="input"
                        label="Telefone"
                        mask="phone"
                        disabled={isDisabled}
                      />
                    </Grid>
                  </>
                ) : (
                  <Grid item desktop={6} />
                )}
              </Grid>
            </Container>
          }
          fallback={
            <Container>
              <Skeleton width="100%" height="62px" variant="text" />
              <Skeleton width="100%" height="62px" variant="text" />
              <Skeleton width="100%" height="62px" variant="text" />
              <Skeleton width="100%" height="62px" variant="text" />
            </Container>
          }
        />
      </Section>

      {/* <Section>
        <RenderSkeletonOrContent
          isLoading={isLoading}
          content={
            <Container title="Dados bancários da empresa">
              <Grid container spacing={4}>
                <Grid item desktop={6}>
                  <Controller
                    name="dadosBancariosDaEmpresa.nome"
                    control={control}
                    type="input"
                    label="Banco"
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="dadosBancariosDaEmpresa.agencia"
                    control={control}
                    type="input"
                    label="Agência"
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="dadosBancariosDaEmpresa.conta"
                    control={control}
                    type="input"
                    label="Conta"
                    mask="bank"
                    disabled={isDisabled}
                  />
                </Grid>
              </Grid>
            </Container>
          }
          fallback={
            <Container>
              <Skeleton width="100%" height="62px" variant="text" />
              <Skeleton width="100%" height="62px" variant="text" />
              <Skeleton width="100%" height="62px" variant="text" />
            </Container>
          }
        />
      </Section> */}
      <Section>
        <DocumentList stage="empresa" />
      </Section>
      {!isDisabled && (
        <Buttons>
          <OutletPermify role={[Roles.EditarCamposDadoGeral]}>
            <Button
              width="170px"
              height="40px"
              type="submit"
              disabled={isDisabled}
            >
              SALVAR DADOS
            </Button>
          </OutletPermify>
        </Buttons>
      )}
    </FormContent>
  );
};
