import styled from 'styled-components';

export const AccordionBaseContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const SectionOne = styled.div`
  display: flex;
  align-items: center;
  img {
    height: 6px;
    width: 10px;
  }
  display: flex;
`;

export const SectionTwo = styled.div`
  margin-right: 2.5%;
`;

export const AccordionContent = styled.div`
  cursor: default;
`;

export const AccordionTitle = styled.p`
  font-weight: 600;
  font-size: 12px;
  color: #171725;
  margin-left: 24px;
  text-transform: uppercase;
  letter-spacing: 1.2px;
`;

export const AccordionContentTitle = styled.h3`
  font-weight: 600;
  font-size: 12px;
  color: #131523;
  height: 48px;
  line-height: 48px;
  letter-spacing: 1.2px;
  margin-left: 60px;
`;

export const AccordionContentDetails = styled.div`
  height: 48px;
  justify-content: center;
  display: flex;
  margin: 0 22px 0 60px;
`;

export const TableLine = styled.hr`
  background-color: #f6f5fc;
  opacity: 0.1;
`;

export const SkelletonAccordion = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  background: white;
  border-radius: 0 0 4px 4px;
`;
