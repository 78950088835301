import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  margin: auto;
  padding-bottom: 170px;
`;

export const Section = styled.div`
  padding-top: 40px;
`;

export const Title = styled.h1`
  color: #171725;
  font-size: 1.5rem;
`;

export const FirstSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-family: Inter;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.17px;
    color: #101219;
  }
`;

export const TableLine = styled.hr`
  background-color: #f6f5fc;
  opacity: 0.1;
`;

export const TableContent = styled.div`
  display: block;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  .save,
  .cancel {
    border-radius: 8px;
    padding: 0.5rem 0 0.5rem 0;
    text-align: center;
    width: 6rem;
    border: none;
  }
  .cancel {
    background-color: #bdbdbd;
    color: #424242;
    margin-right: 1rem;
  }
  .save {
    background-color: #f78157;
    color: #ffffff;
  }
`;

export const ModalTitle = styled.h3`
  display: flex;
  justify-content: start;
  margin: 1rem 0 1.5rem 0;
`;

export const AccordionHeader = styled.div`
  background-color: #c3c6cf33;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16.5px 16px;

  span {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.36px;
  }
`;

export const AccordionSection = styled.div`
  padding: 1rem;
`;

export const AccordionContentDetails = styled.div`
  height: 48px;
  justify-content: center;
  display: flex;
  margin: 0 22px 0 60px;
`;

export const DragContent = styled.div`
  display: flex;
  align-items: center;
  margin-right: 0.8rem;
`;

export const EditArchiveButtonContainer = styled.div`
  margin-left: auto;
  display: flex;
  gap: 1.5rem;
`;

export const EditArchiveButton = styled.button`
  border: none;
  background-color: transparent;
`;

export const CategoryDescription = styled.p`
  font-weight: 400;
  font-size: 12px;
  color: #101219;
  font-family: Inter;
  line-height: 48px;
  letter-spacing: 0.17px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const CheckArchiveButton = styled.button`
  border: none;
  background-color: inherit;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const CheckMainButton = styled.button`
  border: none;
  background-color: inherit;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #2dbcf7;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 2rem;
`;
